import { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../helpers/constants";
import useUserData from "./user/useUserData";
import { enqueueSnackbar } from "notistack";
import { handleAxiosErrors, logoutUnauthorizedUser } from "../helpers/errorHandling";
import useUserAction from "./user/useUserAction";

export const useMuiGridServerSide = ({
										 url,
										 axiosMethod,
										 mapFunction,
										 initialPageSize,
										 initialSortModel,
										 initialFilterModel,
										 initialGroupModel,
										 otherDataParams = {}
									 }) => {
	
	const { token, isLoggedIn } = useUserData()
	const { userLogout } = useUserAction()
	const [rows, setRows] = useState({
		data: [],
		total: 0,
		page: 0,
		pageSize: initialPageSize,
		sortModel: initialSortModel,
		filterModel: initialFilterModel,
		groupModel: initialGroupModel
	})
	const [refresh, setRefresh] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	
	const onPaginationModelChange = (pagingModel, api) => {
		setRows(old => ({ ...old, page: pagingModel.page, pageSize: pagingModel.pageSize }))
	}
	
	const convertSortModel = (sortModel, mapSortModel = undefined) => {
		const model = sortModel.length > 0 ? sortModel.map(item => ({
			name: item.field,
			direction: item.sort === 'desc' ? 'descending' : 'ascending'
		})) : initialSortModel
		return model && mapSortModel ? mapSortModel(model) : model
	}
	
	const onSortModelChange = (sortModel, mapSortModel = undefined) => {
		const model = convertSortModel(sortModel, mapSortModel)
		setRows((old) => ({ ...old, sortModel: model }))
	}
	
	const convertGroupModel = (groupModel, details, mapGroupModel = undefined) => {
		const model = groupModel && groupModel.length > 0 ? groupModel : initialGroupModel
		return model && mapGroupModel ? mapGroupModel(model) : model
	}
	
	const onGroupModelChange = (groupModel, details, mapGroupModel = undefined) => {
		const model = convertGroupModel(groupModel, details, mapGroupModel)
		setRows((old) => ({ ...old, groupModel: model }))
	}
	
	const convertFilterModel = (filterModel, details, mapFilterModel = undefined) => {
		const predicates = []
		const items = filterModel.items
		for (let item of items) {
			if ((item.hasOwnProperty('value') && item.value !== undefined) || (item.operator === 'isEmpty' || item.operator === 'isNotEmpty')) {
				if (item.operator === 'isEmpty' || item.operator === 'isNotEmpty') item.value = null
				if (!(item.value === null && item.operator !== 'isEmpty' && item.operator !== 'isNotEmpty')) {
					predicates.push({
						field: item.field,
						operator: item.operator,
						value: item.operator === 'isAnyOf' ? item?.value.toString() : item?.value,
						isComplex: false,
						ignoreAccent: true,
						ignoreCase: true
					})
				}
			}
		}
		const model = filterModel && predicates.length > 0 ? [{
			isComplex: true,
			condition: filterModel.logicOperator,
			ignoreAccent: false,
			predicates: predicates
		}] : initialFilterModel
		return model && mapFilterModel ? mapFilterModel(model) : model
	}
	
	const onFilterModelChange = (filterModel, details, mapFilterModel = undefined) => {
		if (details.reason === 'deleteFilterItem') {
			setRows((old) => ({ ...old, filterModel: initialFilterModel }))
		} else {
			const model = convertFilterModel(filterModel, details, mapFilterModel)
			setRows((old) => ({ ...old, filterModel: model }))
		}
	}
	
	const refreshGrid = () => {
		setRefresh(prev => !prev)
	}
	
	const dataManager = (customParams = {}) => {
		setIsLoading(true)
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/${url}?skip=${(rows.page) * rows.pageSize}&take=${rows.pageSize}&requiresCount=${true}`,
			data: {
				sorted: rows.sortModel,
				group: rows.groupModel,
				where: rows.filterModel,
				...otherDataParams,
				...customParams
			}
		}).then((response) => {
			setRows((prevState) => {
				return {
					...prevState,
					data: mapFunction ? response.data.result.map(datum => mapFunction(datum)) : response.data.result,
					total: response.data.count,
				}
			})
			setIsLoading(false)
		}).catch((error) => {
			setIsLoading(false)
			enqueueSnackbar(handleAxiosErrors(error), {
				variant: 'error',
			})
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
	}
	
	useEffect(() => {
		let current = true
		setIsLoading(true)
		axios({
			method: 'post',
			headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
			url: `${API}/en/${url}?skip=${(rows.page) * rows.pageSize}&take=${rows.pageSize}&requiresCount=${true}`,
			data: {
				sorted: rows.sortModel,
				group: rows.groupModel,
				where: rows.filterModel,
				...otherDataParams
			}
		}).then((response) => {
			if (current) {
				setRows((prevState) => {
					return {
						...prevState,
						data: mapFunction ? response.data.result.map(datum => mapFunction(datum)) : response.data.result,
						total: response.data.count,
					}
				})
			}
			setIsLoading(false)
		}).catch((error) => {
			setIsLoading(false)
			enqueueSnackbar(handleAxiosErrors(error), {
				variant: 'error',
			})
			logoutUnauthorizedUser(error, isLoggedIn, userLogout)
		})
		
		return () => {
			current = false
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows.page, rows.pageSize, rows.sortModel, rows.filterModel, rows.groupModel, refresh])
	
	return {
		...rows,
		isLoading,
		onPaginationModelChange,
		onSortModelChange,
		onFilterModelChange,
		onGroupModelChange,
		refreshGrid,
		dataManager,
		convertSortModel,
		convertGroupModel,
		convertFilterModel
	}
	
}