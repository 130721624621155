// @flow
import React from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../../hooks/useDebouncedCallback";
import ListItemText from "@mui/material/ListItemText";
import {TextField} from "@mui/material";
import ListItem from "@mui/material/ListItem";

type Props = {};

const SendEmailFromName = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {id, exhibition, updateExhibition} = props
	const onChange = (value) => {
		updateExhibition(id, value, Update.SendFromName)
	}
	const {value, debouncedOnChange} = useDebouncedCallback(onChange, exhibition.sendFromName)
	return (
		<ListItem>
			<ListItemText
				primary={t("Email Sender Name")}
				secondary={t("Use alternative sender for emails. If field remains empty, default sender name will be used.")}
			/>
			<TextField
				defaultValue={value}
				onChange={debouncedOnChange}
				size={"small"}
				fullWidth
				sx={{minWidth: '200px'}}
				inputProps={{inputMode: 'text', maxLength: 250}}
			/>
		</ListItem>
	);
};

export default SendEmailFromName