// @flow
import React from 'react';
import {ListSubheader} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {
	ROUTE_PAGE_EXHIBITION_SETTINGS_IMAGES,
	ROUTE_PAGE_EXHIBITION_SETTINGS_INVITATION_MAIL,
	ROUTE_PAGE_EXHIBITION_SETTINGS_MESSAGES_LIST
} from "../../../../../routers/routes";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import SendFromEmail from "./components/sendFromEmail";
import SendEmailFromName from "./components/sendEmailFromName";
import Typography from "@mui/material/Typography";
import SendSMSFromName from "./components/sendSMSFromName";

type Props = {};

const MessageSettings = (props: Props) => {
	const {id, exhibition, updateExhibition} = props
	const history = useHistory()
	const {t} = useTranslation(EXHIBITION)
	return (
		<List
			subheader={<ListSubheader sx={{backgroundColor: '#F9FBF2'}}><Typography variant={'button'}
																					sx={{color: '#000000'}}>Email/SMS</Typography></ListSubheader>}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_INVITATION_MAIL, {id: id}))
				}}
			>
				<ListItemText primary={t("Email/SMS Text Creation")}/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_MESSAGES_LIST, {id: id}))
				}}
			>
				<ListItemText primary={t("Sent Email/SMS List And Resends")}/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_IMAGES, {id: id}))
				}}
			>
				<ListItemText primary={t("Images For Emails")}/>
			</ListItem>
			<Divider component="li"/>
			<SendFromEmail
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<SendEmailFromName
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<SendSMSFromName
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
		</List>
	);
};

export default MessageSettings