// @flow
import jsonToFormData from "json-form-data";
import { useCallback } from "react";
import { useDispatch } from 'react-redux'
import * as actions from '../../actions/actions'
import { formatDate } from "../../helpers/date";
import { visitorBodyParameters } from "../../helpers/helpers";

export const Update = {
    PreregistrationForm: 0,
    PreregistrationFormEnabled: 1,
    WelcomePage: 3,
    DaysForCatalogSubmission: 4,
    NumberOfPartnersPerExhibitor: 5,
    Logo: 7,
    WelcomeAttachments: 8,
    CatalogLocked: 9,
    PreregistrationDates: 10,
    PreregistrationNeedsApproval: 12,
    CatalogEnableKioskSign: 14,
    ExhibitorInvitesVisitorsWithBarcode: 15,
    ExhibitorVisitors: 16,
    VisitorCategoryRequired: 17,
    PreregistrationRedirect: 19,
    DisableRegistrationMessages: 20,
    PreregistrationHasFieldGroups: 21,
    CatalogueProfileLength: 22,
    ExhibitorServiceProviders: 23,
    hasExhibitorBrands: 24,
    PreregistrationCountry: 25,
    PreregistrationApprove: 26,
    ExhibitorPartners: 27,
    SendFromName: 28,
	SendFromEmail: 29,
	CharacterizationImg: 30,
    isKioskSignEditable: 31,
    RegistrationStartDays: 32,
    RegistrationEndDays: 33,
    ValidQrDays: 34,
    DaysForCatalogNotification: 35,
    CatalogueLanguage: 36,
	ExhibitorKitAndAds: 37,
	ButtonColor: 38,
    GoogleTagCode: 39,
    PreregistrationHeaderImageLink: 40,
    SendSmsName: 41
}

export const UpdateExhibitor = {
    Catalog: 1,
    BusinessCategories: 2,
    CatalogueLength: 3,
    isKioskSignReadOnly: 4
}

const useExhibitionAction = () => {
    const dispatch = useDispatch()

    const getExhibitions = useCallback((pageIndex, pageSize) => {
        dispatch(actions.getExhibitions({
            // requiresCounts: true,
            // skip: pageIndex * pageSize,
            // take: pageSize,
        }))
    }, [dispatch])
    
    const getExhibitionsList = useCallback(() => {
        dispatch(actions.getExhibitionsList())
    }, [dispatch])
    
    const getExhibitionsGroupedHeaders = useCallback(() => {
        dispatch(actions.getExhibitionsGroupedHeaders())
    }, [dispatch])
    
    const getExhibitionsGroupedDetails = useCallback((header) => {
        dispatch(actions.getExhibitionsGroupedDetails({
            ExhibitionName: header
        }))
    }, [dispatch])
    
    
    const getExhibitionDetails = useCallback((id) => {
        dispatch(actions.getExhibitionDetails({
            id: id
        }))
    }, [dispatch])

    const getExhibitionDetailsPublic = useCallback((id) => {
        dispatch(actions.getExhibitionDetailsPublic({
            id: id
        }))
    }, [dispatch])

    const getExhibitionBusinessCategories = useCallback((id) => {
        dispatch(actions.getExhibitionBusinessCategories({
            id: id
        }))
    }, [dispatch])

    const getBadge = useCallback((id) => {
        dispatch(actions.getBadge({
            id: id
        }))
    }, [dispatch])

    const setVisitorApprovalStatus = useCallback((badgeId, status, message = '') => {
        dispatch(actions.setVisitorApprovalStatus({
            badgeId: badgeId,
            status: status,
            message: message
        }))
    }, [dispatch])

    const clearScannedBadge = useCallback(() => {
        dispatch(actions.clearScannedBadge())
    }, [dispatch])

    const registerNewScannedBadge = useCallback(() => {
        dispatch(actions.registerNewScannedBadge())
    }, [dispatch])

    const clearUpdateExhibitionStatus = useCallback(() => {
        dispatch(actions.clearUpdateExhibitionStatus())
    }, [dispatch])

    const updateExhibition = useCallback((id, values, updateType) => {
        let requestValues
        switch (updateType) {
            case Update.CatalogueProfileLength: {
                requestValues = {
                    CatalogueProfileLength: values
                }
                break
            }
            case Update.ExhibitorServiceProviders: {
                requestValues = {
                    ExhibitorServiceProviders: values
                }
                break
            }
            case Update.ExhibitorPartners: {
                requestValues = {
                    ExhibitorPartners: values
                }
                break
            }
            case Update.hasExhibitorBrands: {
                requestValues = {
                    hasExhibitorBrands: values
                }
                break
            }
            case Update.PreregistrationCountry: {
                requestValues = {
                    PreregistrationCountry: values
                }
                break
            }
            case Update.PreregistrationApprove: {
                requestValues = {
                    PreregistrationApprove: values
                }
                break
            }
            case Update.DisableRegistrationMessages: {
                requestValues = {
                    DisableRegistrationMessages: values
                }
                break
            }
            case Update.PreregistrationHasFieldGroups: {
                requestValues = {
                    PreregistrationHasFieldGroups: values
                }
                break
            }
            case Update.ExhibitorInvitesVisitorsWithBarcode: {
                requestValues = {
                    ExhibitorVisitorBarcode: values
                }
                break
            }
            case Update.VisitorCategoryRequired: {
                requestValues = {
                    VisitorCategoryRequired: values
                }
                break
            }
            case Update.CatalogueLanguage: {
                requestValues = {
                    CatalogueLanguage: values
                }
                break
            }
            case Update.PreregistrationRedirect: {
                requestValues = {
                    PreregistrationRedirect: values
                }
                break
            }
            case Update.PreregistrationHeaderImageLink: {
                requestValues = {
                    PreregistrationHeaderImageLink: values
                }
                break
            }
            case Update.GoogleTagCode: {
                requestValues = {
                    GoogleTagCode: values
                }
                break
            }
            case Update.SendFromName: {
                requestValues = {
                    SendFromName: values
                }
                break
            }
            case Update.SendSmsName: {
                requestValues = {
                    SendSmsName: values
                }
                break
            }
            case Update.SendFromEmail: {
                requestValues = {
                    SendFromEmail: values
                }
                break
            }
            case Update.ExhibitorVisitors: {
                requestValues = {
                    ExhibitorVisitors: values
                }
                break
            }
            case Update.ExhibitorKitAndAds: {
                requestValues = {
                    ExhibitorKitAndAds: values
                }
                break
            }
            case Update.PreregistrationForm: {
                requestValues = {
                    PreRegForm: JSON.stringify(values)
                }
                break
            }
            case Update.PreregistrationFormEnabled: {
                requestValues = {
                    isPreregistration: values
                }
                break
            }
            case Update.PreregistrationNeedsApproval: {
                requestValues = {
                    isApproveRegistration: values
                }
                break
            }
            case Update.WelcomePage: {
                requestValues = {
                    WelcomeTextEN: values.text.en,
                    WelcomeTextEL: values.text.el,
                }
                break
            }
            case Update.DaysForCatalogSubmission: {
                requestValues = {
                    DaysForCatalogSubmission: values
                }
                break
            }
            case Update.NumberOfPartnersPerExhibitor: {
                requestValues = {
                    PartnerNum: values
                }
                break
            }
            case Update.isKioskSignEditable: {
                requestValues = {
                    isKioskSignEditable: values
                }
                break
            }
            case Update.RegistrationStartDays: {
                requestValues = {
                    RegistrationStartDays: values
                }
                break
            }
            case Update.RegistrationEndDays: {
                requestValues = {
                    RegistrationEndDays: values
                }
                break
            }
            case Update.ValidQrDays: {
                requestValues = {
                    ValidQrDays: values
                }
                break
            }
            case Update.DaysForCatalogNotification: {
                requestValues = {
                    DaysForCatalogNotification: values
                }
				break
			}
			case Update.ButtonColor: {
				requestValues = {
					ButtonColor: values
				}
                break
            }
            case Update.Logo: {
                const dataToSend = {
                    language: values.language.toUpperCase(),
                    file: values.file[0],
                    id: id,
                    ExhibitionId: id,
                }
                const formData = jsonToFormData(dataToSend)
                return dispatch(actions.updateExhibitionLogo(formData))
            }
            case Update.WelcomeAttachments: {
                const dataToSend = {
                    file: values[0],
                    id: id,
                    ExhibitionId: id,
                    Description: id,
                }
                const formData = jsonToFormData(dataToSend)
                return dispatch(actions.updateExhibitionLogo(formData))
            }
            case Update.CatalogLocked: {
                requestValues = {
                    isProfileLocked: values
                }
                break
            }
            case Update.CatalogEnableKioskSign: {
                requestValues = {
                    CatalogEnableKioskSign: values
                }
                break
            }
            case Update.PreregistrationDates: {
                const value0 = formatDate(values[0], formatDate.formatTypes.DATE_ONLY_FILTER)
                const value1 = formatDate(values[1], formatDate.formatTypes.DATE_ONLY_FILTER)
                requestValues = {
                    StartDtPreregister: value0 !== 'Invalid Date' ? value0 : null,
                    EndDtPreregister: value1 !== 'Invalid Date' ? value1 : null
                }
                break
            }
			case Update.CharacterizationImg: {
				const dataToSend = {
					language: values.language.toUpperCase(),
					file: values.file[0],
					id: id,
					ExhibitionId: id,
				}
				const formData = jsonToFormData(dataToSend)
				return dispatch(actions.updateExhibitionLogo(formData))
			}
            default:
                requestValues = {}
        }
        dispatch(actions.updateExhibition({
            id: id,
            updateType,
            ...requestValues
        }))
    }, [dispatch])

    const getExhibitorProfile = useCallback((id, exhibitionId) => {
        dispatch(actions.getExhibitorProfile({
            id: id,
            exhibitionId: exhibitionId
        }))
    }, [dispatch])

    const getVisitorProfile = useCallback((id, exhibitionId) => {
        dispatch(actions.getVisitorProfile({
            id: id,
            exhibitionId: exhibitionId
        }))
    }, [dispatch])

    const getVisitorExhibitors = useCallback((id, exhibitionId) => {
        dispatch(actions.getVisitorExhibitors({
            id: id,
            exhibitionId: exhibitionId
        }))
    }, [dispatch])

    const setExhibitorEditMode = useCallback((exhibitorId, editMode) => {
        dispatch(actions.setExhibitorEditMode({
            exhibitorId: exhibitorId,
            editMode: editMode
        }))
    }, [dispatch])
    
    const updateExhibitorProfile = useCallback(async (exhibitorId, exhibitionId, values, updateType) => {
        let requestValues
        switch (updateType) {
            case UpdateExhibitor.Catalog:
                requestValues = {
                    addr1CountryId: values.address1.country.en && values.address1.country.en.id ? values.address1.country.en.id : '',
                    addr2CountryId: values.address2.country.en && values.address2.country.en.id ? values.address2.country.en.id : '',
                    addr1PhoneCodeCountryId: values.address1.phoneCode.en && values.address1.phoneCode.en.id ? values.address1.phoneCode.en.id : '',
                    addr2PhoneCodeCountryId: values.address2.phoneCode.en && values.address2.phoneCode.en.id ? values.address2.phoneCode.en.id : '',
                    addr1AddressEL: values.address1.street.el,
                    addr1CityEL: values.address1.city.el,
                    addr1ZipCodeEL: values.address1.postalCode.el,
                    addr1AddrPhoneEL: values.address1.phone.el,
                    addr1AddressEN: values.address1.street.en,
                    addr1CityEN: values.address1.city.en,
                    addr1ZipCodeEN: values.address1.postalCode.en,
                    addr1AddrPhoneEN: values.address1.phone.en,
                    addr2AddressEL: values.address2.street.el,
                    addr2CityEL: values.address2.city.el,
                    addr2ZipCodeEL: values.address2.postalCode.el,
                    addr2AddrPhoneEL: values.address2.phone.el,
                    addr2AddressEN: values.address2.street.en,
                    addr2CityEN: values.address2.city.en,
                    addr2ZipCodeEN: values.address2.postalCode.en,
                    addr2AddrPhoneEN: values.address2.phone.en,
                    EmailCatalogue: values.social.email,
                    CatalogProfileEN: values.profile.en,
                    CatalogProfileEL: values.profile.el,
                    CatalogBrandEL: values.brand.el,
                    CatalogBrandEN: values.brand.en,
                    WebSite: values.social.website,
                    Facebook: values.social.fb,
                    Instagram: values.social.instagram,
                    LinkedIn: values.social.linkedIn,
                    Brands: [values.brands0, values.brands1, values.brands2, values.brands3, values.brands4, values.brands5, values.brands6, values.brands7, values.brands8, values.brands9].filter(brand => brand !== ""),
                    KioskSign: values.kioskSign
                }
                break
            case UpdateExhibitor.BusinessCategories:
                requestValues = {
                    BusinessCategs: values.businessCategories.map(v => v.id),
                }
                break
            case UpdateExhibitor.CatalogueLength:
                requestValues = {
                    CatalogueProfileLength: values.catalogueProfileLength
                }
                break
            case UpdateExhibitor.isKioskSignReadOnly:
                requestValues = {
                    isKioskSignReadOnly: values.isKioskSignReadOnly
                }
                break
            default:
                requestValues = {}
        }
        dispatch(actions.updateExhibitorProfile({
            exhibitorId: exhibitorId,
            id: exhibitionId,
            updateType,
            ...requestValues
        }))
    }, [dispatch])

    const createVisitor = useCallback((exhibitionId, exhibitorId, values, isRegistration = false, isUpdate, sendMessages) => {
        const dataToSend = visitorBodyParameters({
            exhibitionId,
            exhibitorId,
            isRegistration,
            isUpdate,
            sendMessages
        }, values)
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.createVisitor(formData))
    }, [dispatch])

    const updateVisitor = useCallback((exhibitionId, visitorId, values) => {
        const dataToSend = visitorBodyParameters({exhibitionId, visitorId}, values)
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.updateVisitor(formData))
    }, [dispatch])

    const createPartner = useCallback((exhibitionId, exhibitorId, values, type) => {
        dispatch(actions.createPartner({
            ExhibitionId: exhibitionId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {}),
            email: values.email,
            Name: values.name,
            NameEN: values.nameEn,
            Type: type,
            Company: values.companyName
        }))
    }, [dispatch])

    const createBulkVisitors = useCallback((exhibitionId, exhibitorId, values,) => {
        const dataToSend = {
            VisitorApproved: values.visitorApproved,
            file: values.files[0],
            ExhibitionId: exhibitionId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {})
        }
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.createBulkVisitors(formData))
    }, [dispatch])

    const createBulkVisitorsForBadges = useCallback((exhibitionId, values) => {
        const dataToSend = {
            file: values.files[0],
            ExhibitionId: exhibitionId
        }
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.creatBulkVisitorsForBadges(formData))
    }, [dispatch])

    const clearBulkVisitorStatus = useCallback(() => {
        dispatch(actions.clearBulkVisitorStatus())
    }, [dispatch])

    const createBulkPartners = useCallback((exhibitionId, exhibitorId, values, type) => {
        const dataToSend = {
            file: values.files[0],
            ExhibitionId: exhibitionId,
            ExhibitorId: exhibitorId,
            Type: type
        }
        const formData = jsonToFormData(dataToSend)
        dispatch(actions.createBulkPartners(formData))
    }, [dispatch])

    const clearBulkPartnersStatus = useCallback(() => {
        dispatch(actions.clearBulkPartnersStatus())
    }, [dispatch])

    const clearActionOnVisitorStatus = useCallback(() => {
        dispatch(actions.clearActionOnVisitorStatus())
    }, [dispatch])

    const clearActionOnExhibitorStatus = useCallback(() => {
        dispatch(actions.clearActionOnExhibitorStatus())
    }, [dispatch])

    const clearActionOnPartnerStatus = useCallback(() => {
        dispatch(actions.clearActionOnPartnerStatus())
    }, [dispatch])
    
    const removePartner = useCallback((exhibitionId, exhibitorId, id) => {
        dispatch(actions.removePartner({
            id: id,
            ExhibitionId: exhibitionId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {})
        }))
    }, [dispatch])

    const removeVisitor = useCallback((exhibitionId, exhibitorId, id) => {
        dispatch(actions.removeVisitor({
            id: id,
            ExhibitionId: exhibitionId,
            ...(exhibitorId ? {ExhibitorId: exhibitorId} : {})
        }))
    }, [dispatch])
    
    const deleteMultipleVisitors = useCallback((ids, exhibitionId) => {
        dispatch(actions.deleteMultipleVisitors({
            ids: ids,
            ExhibitionId: exhibitionId,
        }))
    }, [dispatch])

    const sendVisitorInvitations = useCallback((exhibitionId, exhibitorId, type, ids, recipientEmail, returnCount) => {
        dispatch(actions.sendVisitorInvitations({
            type: type,
            ExhibitionId: exhibitionId,
            exhibitorId: exhibitorId,
            ids: ids,
            returnCount: returnCount,
            ...(recipientEmail ? {sendto: recipientEmail} : {})
        }))
    }, [dispatch])

    const sendExhibitorInvitations = useCallback((exhibitionId, type, ids, recipientEmail, returnCount) => {
        dispatch(actions.sendExhibitorInvitations({
            type: type,
            ExhibitionId: exhibitionId,
            ids: ids,
            returnCount: returnCount,
            ...(recipientEmail ? {sendto: recipientEmail} : {})
        }))
    }, [dispatch])

    const sendPartnerBadges = useCallback((ids, recipientEmail) => {
        dispatch(actions.sendPartnerBadges({
            ids: ids,
            ...(recipientEmail ? {sendto: recipientEmail} : {})
        }))
    }, [dispatch])

    const exhibitorCatalogueConfirm = useCallback((exhibitionId, exhibitorId) => {
        dispatch(actions.exhibitorCatalogueConfirm({
            exhibitionId: exhibitionId,
            exhibitorId: exhibitorId
        }))
    }, [dispatch])

    const exhibitorCatalogueConfirmReset = useCallback((exhibitionId, exhibitorId) => {
        dispatch(actions.exhibitorCatalogueConfirmReset({
            exhibitionId: exhibitionId,
            exhibitorId: exhibitorId
        }))
    }, [dispatch])
	
	const updateQuickPrintFooterImage = useCallback((id, values) => {
		dispatch(actions.updateQuickPrintFooterImage({
			id: id,
			language: values.language.toUpperCase(),
			file: values.file[0],
		}))
	}, [dispatch])
	
	const deleteQuickPrintFooterImage = useCallback((exhibitionId, language) => {
		dispatch(actions.deleteQuickPrintFooterImage({
			id: exhibitionId,
			language: language.toUpperCase()
		}))
	}, [dispatch])
    
    const setExhibitionMessage = useCallback((message) => {
        dispatch(actions.setExhibitionMessage(message))
    }, [dispatch])
    
    const copyExhibitionSettings = useCallback((fromExhibitionId, toExhibitionId) => {
        dispatch(actions.copyExhibitionSettings({
            fromExhibition: fromExhibitionId,
            toExhibition: toExhibitionId
        }))
    }, [dispatch])

    return {
        getExhibitionsList,
        getExhibitionBusinessCategories,
        getVisitorExhibitors,
        registerNewScannedBadge,
        sendPartnerBadges,
        sendVisitorInvitations,
        sendExhibitorInvitations,
        removePartner,
        removeVisitor,
        clearActionOnVisitorStatus,
        clearActionOnExhibitorStatus,
        clearActionOnPartnerStatus,
        getVisitorProfile,
        getExhibitorProfile,
        clearScannedBadge,
        getExhibitionDetails,
        getExhibitionDetailsPublic,
        getExhibitions,
        getBadge,
        setVisitorApprovalStatus,
        clearUpdateExhibitionStatus,
        updateExhibition,
        updateExhibitorProfile,
        createVisitor,
        updateVisitor,
        createBulkVisitors,
        clearBulkVisitorStatus,
        createBulkVisitorsForBadges,
        createPartner,
        createBulkPartners,
        clearBulkPartnersStatus,
        exhibitorCatalogueConfirm,
        exhibitorCatalogueConfirmReset,
		setExhibitorEditMode,
		updateQuickPrintFooterImage,
        deleteQuickPrintFooterImage,
        deleteMultipleVisitors,
        getExhibitionsGroupedHeaders,
        getExhibitionsGroupedDetails,
        setExhibitionMessage,
        copyExhibitionSettings
    }
}

export default useExhibitionAction
