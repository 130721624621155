//@flow
import type {Exhibition} from "../../../../../../types/exhibition/types";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../../hooks/useDebouncedCallback";
import ListItem from "@mui/material/ListItem";
import {TextField} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";

type Props = {
    id: string,
    exhibition: Exhibition,
    updateExhibition: Function
}

const PreregistrationHeaderImageLink = (props: Props) => {
    const {t} = useTranslation(EXHIBITION)
    const {id, exhibition, updateExhibition} = props
    const onChange = (value) => {
        updateExhibition(id, value, Update.PreregistrationHeaderImageLink)
    }
    const {value, debouncedOnChange} = useDebouncedCallback(onChange, exhibition.preregistrationHeaderImageLink)
    return (
        <ListItem disabled={!exhibition.preregistrationEnabled}>
            <ListItemText
                primary={t("Preregistration Form Header Logo Link")}
                secondary={t("Set an external link in preregistration form header logo.")}
            />
            <TextField
                disabled={!exhibition.preregistrationEnabled}
                defaultValue={value}
                onChange={debouncedOnChange}
                size={"small"}
                fullWidth
                sx={{minWidth: '200px'}}
                inputProps={{inputMode: 'text', maxLength: 250}}
            />
        </ListItem>
    )
}

export default PreregistrationHeaderImageLink
