// @flow
import { enqueueSnackbar } from "notistack";
import React from 'react';
import {useTranslation} from "react-i18next";
import {EXHIBITION} from "../../../../../../locales/components/namespaces";
import {Update} from "../../../../../../hooks/exhibitions/useExhibitionAction";
import {useDebouncedCallback} from "../../../../../../hooks/useDebouncedCallback";
import ListItemText from "@mui/material/ListItemText";
import {TextField} from "@mui/material";
import ListItem from "@mui/material/ListItem";

type Props = {};

const SendSMSFromName = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {id, exhibition, updateExhibition} = props
	const onChange = (value) => {
		if(value.match(/^([A-Za-z^!@#$%&*()_+\-\\|{}[\].,<>?'":;/`=\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s\w]*)$/gi)) {
			updateExhibition(id, value, Update.SendSmsName)
		} else {
			enqueueSnackbar(t('SMS sender name must be in Latin characters. Up to 11 characters allowed.'), {
					variant: 'warning'
				}
			)
		}
	}
	const {value, debouncedOnChange} = useDebouncedCallback(onChange, exhibition.sendSmsName)
	return (
		<ListItem>
			<ListItemText
				primary={t("SMS Sender Name")}
				secondary={t("Use alternative sender name for SMS. If field remains empty, default sender name will be used. (Up to 11 latin characters)")}
			/>
			<TextField
				defaultValue={value}
				onChange={debouncedOnChange}
				size={"small"}
				fullWidth
				sx={{minWidth: '200px'}}
				inputProps={{inputMode: 'text', maxLength: 11}}
			/>
		</ListItem>
	);
};

export default SendSMSFromName