// @flow
import { enqueueSnackbar } from "notistack";
import * as React from 'react';
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import ExhibitionUpdateStatus from "../../../components/app/alerts/exhibitionUpdateStatus/exhibitionUpdateStatus";
import { handleAxiosErrors } from "../../../helpers/errorHandling";
import {normalizeGreek} from "../../../helpers/normalizeGreek";
import {useTranslation} from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import * as yup from "yup";
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";
import {EXHIBITOR_PROFILE} from "../../../locales/components/namespaces";
import SaveIcon from "@mui/icons-material/Save";
import useExhibitionAction, {UpdateExhibitor} from "../../../hooks/exhibitions/useExhibitionAction";

type Props = {};

export const KioskSignEditable = (props: Props) => {
	const {kioskSignEditable, handleKioskSignEditable, refreshGrid, exhibitionId} = props
	const {t} = useTranslation(EXHIBITOR_PROFILE)
	const {updateExhibitorProfile} = useExhibitionAction()
	
	const onSubmit = (values) => {
		updateExhibitorProfile(kioskSignEditable.exhibitorId, exhibitionId, values, UpdateExhibitor.isKioskSignReadOnly, refreshGrid)
		.then(() => {
			refreshGrid();
			handlePopoverClose()
		})
	}
	
	const validationSchema = yup.object({})
	
	const formik = useFormik({
		initialValues: {
			isKioskSignReadOnly: kioskSignEditable.value
		},
		validationSchema: validationSchema,
		onSubmit: onSubmit,
		enableReinitialize: true
	})
	
	const handlePopoverClose = () => {
		handleKioskSignEditable({anchorEl: null, open: false, exhibitorId: '', value: false});
		formik.resetForm()
	}
	
	return (
		<React.Fragment>
			<Box onClick={(e) => e.stopPropagation()}>
				<Popover
					disableRestoreFocus
					onClose={handlePopoverClose}
					open={kioskSignEditable.open}
					anchorEl={kioskSignEditable.anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
				>
					<FormikProvider value={formik}>
						<Box component="form" onSubmit={formik.handleSubmit} sx={{width: '100%', minWidth: '400px'}}>
							<Box sx={{width: '100%', padding: '20px'}}>
								<FormControl
									variant="standard"
									fullWidth
									error={
										formik.touched["isKioskSignReadOnly"] &&
										Boolean(formik.errors["isKioskSignReadOnly"])
									}
								>
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.isKioskSignReadOnly}
												onChange={formik.handleChange}
												size={'small'}
												sx={{p: 0, mb: 0, mt: 0, mr: 0.4, ml: 1}}
												name={`isKioskSignReadOnly`}
												onBlur={formik.handleBlur}
											/>
										}
										label={t('Kiosk Sign Not Editable')}
										labelPlacement="end"
									/>
									<FormHelperText>
										<ErrorMessage name={"isKioskSignReadOnly"}/>
									</FormHelperText>
								</FormControl>
							</Box>
							<Box sx={{
								padding: '0 20px 20px',
								display: 'flex',
								width: '100%',
								justifyContent: 'space-between'
							}}>
								<Button
									size={"small"}
									type={"submit"}
									variant={"contained"}
									startIcon={<SaveIcon/>}
								>
									{normalizeGreek(t('Save'))}
								</Button>
								<Button
									size={"small"}
									variant={"outlined"}
									startIcon={<CancelIcon/>}
									type={"button"}
									onClick={handlePopoverClose}
								>
									{normalizeGreek(t('Cancel'))}
								</Button>
							</Box>
						</Box>
					</FormikProvider>
				</Popover>
				<ExhibitionUpdateStatus/>
			</Box>
		</React.Fragment>
	);
};