//@flow
import React, {useMemo} from 'react';
import List from "@mui/material/List";
import {ListSubheader, Switch} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import {getRouteUrl} from "../../../../../helpers/getRouteUrl";
import {ROUTE_PAGE_EXHIBITION_SETTINGS_PREREGISTRATION_FIELDS,} from "../../../../../routers/routes";
import {Update} from "../../../../../hooks/exhibitions/useExhibitionAction";
import type {Exhibition} from "../../../../../types/exhibition/types";
import {useHistory} from "react-router-dom";
import {EXHIBITION} from "../../../../../locales/components/namespaces";
import {useTranslation} from "react-i18next";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CopyToClipboard from "../../../../../components/general/copyToClipboard/copyToClipboard";
import {AdapterDayjs} from "@mui/x-date-pickers-pro/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers-pro";
import PreregistrationGTAG from "./components/preregistrationGTAG";
import PreregistrationHeaderImageLink from "./components/preregistrationHeaderImageLink";
import PreregistrationRedirect from "./components/preregistrationRedirect";
import PreregistrationCountries from "./components/preregistrationCountries";
import PreregistrationCountriesApproval from "./components/preregistrationCountriesApproval";
import {PreregistrationRegions, PreregistrationRegionsApproval} from "../../../../../helpers/constants";
import Typography from "@mui/material/Typography";
import {normalizeGreek} from "../../../../../helpers/normalizeGreek";
import {muiDatePickersLocales} from "../../../../../helpers/muiGridLocales";
import useUserData from "../../../../../hooks/user/useUserData";

type Props = {
	id: string,
	updateExhibition: Function,
	exhibition: Exhibition,
}

const PreregistrationSettings = (props: Props) => {
	const {t} = useTranslation(EXHIBITION)
	const {exhibition, updateExhibition, id} = props
	const history = useHistory()
	const {language, dateFormat} = useUserData()
	const [value, setValue] = React.useState([exhibition.preregistrationFrom, exhibition.preregistrationTo])
	const togglePreregistrationEnabled = () => {
		updateExhibition(id, !exhibition.preregistrationEnabled, Update.PreregistrationFormEnabled)
	}
	const togglePreregistrationNeedsApproval = (evt) => {
		updateExhibition(id, !exhibition.preregistrationNeedsApproval, Update.PreregistrationNeedsApproval)
		let preregistrationApprovePerCountry = PreregistrationRegionsApproval.NoApproval
		if (evt.target.checked === true) {
			if (exhibition.preregistrationCountry === PreregistrationRegions.AbroadOnly) preregistrationApprovePerCountry = PreregistrationRegionsApproval.AbroadOnly
			if (exhibition.preregistrationCountry === PreregistrationRegions.GreeceOnly) preregistrationApprovePerCountry = PreregistrationRegionsApproval.GreeceOnly
			if (exhibition.preregistrationCountry === PreregistrationRegions.Everywhere) preregistrationApprovePerCountry = PreregistrationRegionsApproval.Everywhere
		}
		updateExhibition(id, preregistrationApprovePerCountry, Update.PreregistrationApprove)
	}
	const togglePreregistrationHasFieldGroups = () => {
		updateExhibition(id, !exhibition.preregistrationHasFieldGroups, Update.PreregistrationHasFieldGroups)
	}
	const updateDates = (newV, index) => {
		const newValue = value.map((v, j) => j === index ? newV : v)
		updateExhibition(id, newValue, Update.PreregistrationDates)
		setValue(newValue)
	}
	
	const preregistrationPublicUrl = useMemo(() => (
		`${exhibition.domainPreregistration}/invite-register?event=${exhibition.id}`
	), [exhibition.domainPreregistration, exhibition.id])
	const preregistrationPreviewUrl = useMemo(() => (
		`${exhibition.domainPreregistration}/invite-register?event=${exhibition.id}&isPreview=true`
	), [exhibition.domainPreregistration, exhibition.id])
	return (
		<List
			subheader={<ListSubheader sx={{backgroundColor: '#F9FBF2'}}><Typography variant={'button'}
																					sx={{color: '#000000'}}>{normalizeGreek(t('Preregistration'))}</Typography></ListSubheader>}
			sx={{mb: 1, pb: 0, width: '100%', bgcolor: 'background.paper', border: '1px solid #8c9eff'}}
		>
			<ListItem>
				<ListItemText
					primary={t("Enable preregistration")}
				/>
				<Switch
					edge="end"
					onChange={togglePreregistrationEnabled}
					checked={exhibition.preregistrationEnabled}
				/>
			</ListItem>
			{exhibition.preregistrationEnabled && (
				<CopyToClipboard
					text={preregistrationPublicUrl}
				/>
			)}
			<Divider component="li"/>
			{exhibition.preregistrationEnabled && (
				<CopyToClipboard
					text={preregistrationPreviewUrl}
				/>
			)}
			<Divider component="li"/>
			<PreregistrationCountries
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ListItem
				disabled={!exhibition.preregistrationEnabled}
			>
				<ListItemText
					primary={t("Preregistration Requires Approval")}
					secondary={
						<React.Fragment>
							{t('By enabling this option organizers will have to approve/reject users, after they complete their preregistration.')}
						</React.Fragment>
					}
				/>
				<Switch
					edge="end"
					onChange={togglePreregistrationNeedsApproval}
					checked={exhibition.preregistrationNeedsApproval}
					disabled={!exhibition.preregistrationEnabled}
				/>
			</ListItem>
			<Divider component="li"/>
			<PreregistrationCountriesApproval
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ListItem
				disabled={!exhibition.preregistrationEnabled}
			>
				<ListItemText
					primary={t("Preregistration Dates")}
					secondary={t('Set the date range when preregistration is allowed.')}
				/>
				<Box>
					<LocalizationProvider dateAdapter={AdapterDayjs} localeText={muiDatePickersLocales(language)}>
						<DatePicker
							label={t('Start')}
							value={value[0]}
							format={dateFormat}
							onChange={(newValue) => {
								updateDates(newValue, 0);
							}}
							renderInput={(params) => <TextField size={"small"} {...params} />}
							disabled={!exhibition.preregistrationEnabled}
						/>
						<Box sx={{my: 1}}/>
						<DatePicker
							format={dateFormat}
							size={"small"}
							label={t('End')}
							value={value[1]}
							onChange={(newValue) => {
								updateDates(newValue, 1);
							}}
							renderInput={(params) => <TextField size={"small"} {...params} />}
							disabled={!exhibition.preregistrationEnabled}
						/>
					</LocalizationProvider>
				</Box>
			</ListItem>
			<Divider component="li"/>
			<PreregistrationRedirect
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<PreregistrationHeaderImageLink
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<PreregistrationGTAG
				id={id}
				exhibition={exhibition}
				updateExhibition={updateExhibition}
			/>
			<Divider component="li"/>
			<ListItem disabled={!exhibition.preregistrationEnabled}>
				<ListItemText
					primary={t("Display Preregistration Form Fields In Groups")}
					secondary={t("If enabled fields of preregistration form are grouped under two groups. You can set a specific group for each field in Field Settings.")}
				/>
				<Switch
					edge="end"
					onChange={togglePreregistrationHasFieldGroups}
					checked={exhibition.preregistrationHasFieldGroups}
					disabled={!exhibition.preregistrationEnabled}
				/>
			</ListItem>
			<Divider component="li"/>
			<ListItem
				button
				onClick={() => {
					history.push(getRouteUrl(ROUTE_PAGE_EXHIBITION_SETTINGS_PREREGISTRATION_FIELDS, {id: id}))
				}}
				alignItems="flex-start"
			>
				<ListItemText
					primary={t("Field Settings")}
					secondary={
						<React.Fragment>
							{t('Customize preregistration form layout, enabled-disabled fields etc')}
						</React.Fragment>
					}
				/>
			</ListItem>
		</List>
	)
}

export default PreregistrationSettings;
